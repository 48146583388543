// Imports
// ------
import React, { useEffect } from 'react';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';
import { menuState } from '@states/menu';

// Styles
// ------
import { Jacket, Content, Title, Heading } from './styles';

// Component
// ------
function Error404() {
	useEffect(() => {
		menuState.isHidden = true;

		return () => {
			menuState.isHidden = false;
		};
	}, []);
	return (
		<Jacket>
			<Row isExpanded isCenter>
				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					large={8}
					pushOnLarge={2}
					mpad>
					<Content>
						<Title>Agh!</Title>
						<Heading>
							You shouldnt have ended up here, let’s get you to a safe place...
						</Heading>
						<Button label='Take me home' to='' isLight />
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Error404;
