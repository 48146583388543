// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, H3 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		${props.theme.vhs}
		position: relative;
		/* overflow: hidden; */

		&:before {
			content: '';
			position: fixed;
			z-index: 0;
			top: -12rem;
			bottom: -12rem;
			left: 0;
			right: 0;
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
		}

		${breakup.large`
            ${props.theme.vh}
        `}
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;
		z-index: 2;
		text-align: center;
		margin: 3.6rem 0 0 0;
	`
);

const sharedStyles = (props) => css`
	color: ${props.theme.white};
	margin: 0 0 2.4rem 0;
`;

export const Title = styled(H1)(
	(props) => css`
		${sharedStyles}
	`
);

export const Heading = styled(H3)(
	(props) => css`
		${sharedStyles}

		span {
			display: inline;

			${breakup.large`
                display: block;
            `}
		}
	`
);
